.dragging-over {
  background-color: purple !important;
  opacity: 0.8;
  color: white;
  border: 0;
}


.number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button {
  height: 40px;
  width: 40px;
  margin-top: -5px; /* Adjust this value to center the buttons vertically */
  font-size: 123;
}

#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}
#form-file-upload {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.rbc-calendar {
  height: 1200px !important;
  width: 100%;
}
@media screen and (max-width: 3000px) {
  .rbc-calendar {
    height: 1000px !important;
    width: 100%;
  }
}
@media screen and (max-width: 2000px) {
  .rbc-calendar {
    height: 700px !important;
    width: 100%;
  }
}
@media screen and (max-width: 1600px) {
  .rbc-calendar {
    height: 650px !important;
    width: 100%;
  }
}
@media screen and (max-width: 1400px) {
  .rbc-calendar {
    height: 550px !important;
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .rbc-calendar {
    height: 500px !important;
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .rbc-calendar {
    height: 400px !important;
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .rbc-calendar {
    height: 400px !important;
  }
}

@media screen and (max-width: 600px) {
  .rbc-calendar {
    height: 200% !important;
  }
}

@media screen and (max-width: 300px) {
  .rbc-calendar {
    height: 100% !important;
  }
}

